import i18next from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { I18nextProvider, withTranslation } from "react-i18next";

export function withTrans(WrappedComponent) {
  WrappedComponent = withTranslation()(WrappedComponent);
  const Component = props => {
    const sharedLabel = Object.fromEntries(
      props.data.prismicSharedLabels.data.shared_label.map(sl => [
        sl.label_key,
        sl.label_value.map(l => l.text).join("\n"),
      ])
    );

    i18next.init({
      fallbackLng: "fr",
      resources: {
        fr: {
          translations: sharedLabel,
        },
      },
      ns: ["translations"],
      defaultNS: "translations",
      returnObjects: true,
      interpolation: {
        escapeValue: false, // not needed for react!!
        format: function (value, format) {
          if (format === "bold") return <strong>{value}</strong>;
          if (format === "uppercase") return value.toUpperCase();
          if (format === "breakLine") return <p>{value}</p>;
          return value;
        },
      },
      react: {
        useSuspense: true,
      },
    });

    i18next.languages = ["fr"];

    return (
      <I18nextProvider i18n={i18next}>
        <WrappedComponent {...props} language={i18next.language} />
      </I18nextProvider>
    );
  };

  Component.propTypes = {
    data: PropTypes.object,
  };

  return Component;
}
