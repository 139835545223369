export default data => {
  const {
    primary,
    items,
    sponsors,
    sponsors_title,
    sponsors_description,
  } = data;
  const sponsorsData = items || sponsors || {};
  const sponsorsTitle = primary?.sponsors_title || sponsors_title || null;
  const sponsorsDescription =
    primary?.sponsors_description || sponsors_description || null;

  return {
    title: sponsorsTitle,
    description: sponsorsDescription?.raw,
    sponsors: sponsorsData
      ?.map(sponsor => ({ ...sponsorAdapter(sponsor) }))
      .filter(sponsor => !!sponsor.logo),
  };
};

export const sponsorAdapter = ({ sponsor }) => {
  const { uid } = sponsor?.document || {};
  const { sponsor_link, sponsor_logo } = sponsor?.document?.data || {};

  return {
    uid,
    link: sponsor_link,
    logo: sponsor_logo?.thumbnails?.partner || sponsor_logo,
  };
};
