export default seoData => {
  const { data, url, status } = seoData || {};
  const { meta_title, meta_description, meta_image } = data || {};

  return {
    metaTitle: meta_title,
    metaDescription: meta_description,
    metaImage: meta_image?.url,
    url,
    status,
  };
};
