import { slugify } from "../../../../styles/theme";

export default (
  {
    title,
    description,
    director,
    duration,
    category,
    genre,
    year,
    country,
    language,
    video_id,
    video_player,
    video_embed,
    video_price,
    smartadserver_disable_preroll,
    trailer_id,
    trailer_player,
    trailer_embed,
    showVideoButtons,
    showDescription,
  },
  { t }
) => {
  return {
    title: title || description?.raw,
    subtitle: director && `${t("curiosity_director_prefix")} ${director}`,
    description: description?.raw,
    duration,
    category,
    genre,
    year,
    country,
    language,
    showVideoButtons: showVideoButtons || true,
    showDescription: showDescription || true,
    video: {
      title: t("curiosity_information_video", { title: title }),
      id: video_id,
      player: video_player,
      embed: video_embed,
      price: video_price,
      disablePreroll: smartadserver_disable_preroll,
    },
    trailer: {
      title: t("curiosity_information_trailer", { title: title }),
      id: trailer_id,
      player: trailer_player,
      embed: trailer_embed,
      price: "freemium",
    },
    ctaLabelVideo: category
      ? t(`curiosity_information_playlabel_${slugify(category)}`)
      : t("curiosity_information_playlabel"),
    ctaLabelTrailer: category
      ? t(`curiosity_information_playtrailerlabel_${slugify(category)}`)
      : t("curiosity_information_playtrailerlabel"),
  };
};

export const guestInformationsAdapter = ({
  guest_title,
  guest_description,
}) => {
  return {
    title: guest_title,
    description: guest_description?.raw,
  };
};

export const collectionInformationsAdapter = ({
  collection_title,
  collection_description,
}) => {
  return {
    title: collection_title,
    description: collection_description?.raw,
  };
};
