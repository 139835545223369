import { sponsorAdapter } from "../../Molecules/Sponsors/adapter";

export default (
  {
    actors,
    description,
    sponsors,
    sponsors_title,
    sponsors_description,
    duration,
    category,
    genre,
    language,
    tags,
  },
  { t }
) => {
  return {
    description: description?.raw,
    actors: actors
      ?.map(({ actor_name, actor_role }) => {
        return {
          name: actor_name,
          role: actor_role,
        };
      })
      .filter(actor => !!actor?.name),
    sponsorsTitle: sponsors_title,
    sponsorsDescription: sponsors_description?.raw,
    sponsors: sponsors
      ?.map(sponsor => ({ ...sponsorAdapter(sponsor) }))
      .filter(sponsor => !!sponsor.logo),
    titleIntro: t("curiosity_distribution_synopsis_title"),
    titleActors: t("curiosity_distribution_actors_title"),
    titleTags: t("curiosity_distribution_tags_title"),
    duration,
    category,
    genre,
    language,
    tags,
  };
};
