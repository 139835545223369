import React, { memo } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

function JsonLd({ children }) {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(children)}</script>
    </Helmet>
  );
}

JsonLd.propTypes = {
  children: PropTypes.object,
};

export default memo(JsonLd);
