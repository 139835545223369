export const configAdapter = config => {
  return Object.assign(
    ...config.map(({ key, value }) => {
      if (parseInt(value)) return { [key]: parseInt(value) };
      else
        return {
          [key]: value === "true" ? true : value === "false" ? false : value,
        };
    })
  );
};

export const adConfigAdapter = (
  {
    ad_fallback_link,
    ad_fallback_image,
    smartadserver_page_name,
    smartadserver_page_id,
    smartadserver_disable_masthead,
    adDevConfig,
    adConfig,
  },
  type
) => {
  const enableTestAds = process.env.GATSBY_ENABLE_TEST_ADS === "true";
  if (!adDevConfig && !adConfig) return;
  const config = configAdapter(enableTestAds ? adDevConfig : adConfig);
  return {
    ...config,
    image: ad_fallback_image,
    link: ad_fallback_link,
    smartadserver_pgname: smartadserver_page_name
      ? smartadserver_page_name
      : config[`smartadserver_page_name_${type}`],
    smartadserver_page_id: smartadserver_page_id
      ? smartadserver_page_id
      : config[`smartadserver_page_id_${type}`],
    smartadserver_disable_masthead,
  };
};
