import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  colors,
  mediaquery,
  rem,
  scaleSpacing,
} from "../../../../styles/theme";
import Image from "../../Atoms/Image";
import Link from "../../Atoms/Link";
import Button from "../../Atoms/Button";
import RichText from "../../Atoms/RichText";
import {
  NotoBody,
  OswaldOverline1,
  OswaldOverline3,
  OswaldH4,
  OswaldH5,
} from "../../Atoms/Typography";

const Container = styled.section`
  background-color: ${colors.darkgrey2};
  padding: ${scaleSpacing(20)} calc(var(--external-gutter));
  display: flex;
  flex-direction: column;

  ${mediaquery.sm(css`
    padding: ${scaleSpacing(25)} calc(var(--external-gutter) + var(--col));
  `)};
`;

const SectionTitle = styled.h2`
  color: ${colors.white};
  margin-bottom: ${scaleSpacing(6)};

  ${OswaldOverline3};
  color: ${colors.lightgrey};

  ${mediaquery.md(css`
    ${OswaldOverline1};
  `)};
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaquery.sm(css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `)};
`;

const ImageWrapper = styled(Image)`
  width: 100%;
  ${mediaquery.md(css`
    width: calc(var(--col) * 13 - var(--gutter-size));
  `)};
`;

const Content = styled.div`
  color: ${colors.white};
  width: 100%;

  ${mediaquery.sm(css`
    margin-top: 0;
    width: calc(var(--col) * 8);
    margin-left: var(--col);
  `)};
`;

const Description = styled.div`
  ${NotoBody};
  line-height: ${rem(30)};
  letter-spacing: 0.1px;
  margin-top: ${scaleSpacing(6)};
  margin-bottom: ${scaleSpacing(6)};

  strong {
    ${OswaldH5}
    padding: ${scaleSpacing(6)} 0;
    display: block;
  }

  ${mediaquery.sm(css`
    margin-top: ${scaleSpacing(6)};
    strong {
      ${OswaldH4}
    }
  `)};
`;

const TagList = styled.ul`
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  margin: ${scaleSpacing(6)} 0 ${scaleSpacing(8)} 0;
  display: flex;

  .cols2 && {
    margin: ${scaleSpacing(6)} 0;
  }
`;

const TagListItem = styled.li`
  display: flex;
  position: relative;
  margin: 0 ${scaleSpacing(4)} ${scaleSpacing(1)} 0;

  &:last-of-type {
    margin-right: 0;
  }

  &:after {
    content: "";
    width: 1px;
    height: 9px;
    transform: translate(-8px, 10px);
    background-color: ${colors.mediumgrey3};
    position: absolute;
    right: -${scaleSpacing(4)};
    top: 0;
  }
  &:last-of-type:after {
    display: none;
  }
`;

const StyledTag = styled(Button)`
  ${NotoBody};
  line-height: ${rem(16)};
  background-color: transparent;
  color: ${colors.mediumgrey3};

  padding: ${scaleSpacing(1)} 0;
`;

const Edito = ({ description, picture, link, label, author, date }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <SectionTitle>{t("curiosity_filmdebate_title")}</SectionTitle>
      <ContentWrapper>
        {picture && (
          <ImageWrapper source={picture} sizes="50vw" mobileSizes="100vw" />
        )}

        <Content>
          {description && (
            <Description>
              <RichText data={description} />
            </Description>
          )}

          {(date || author) && (
            <TagList>
              {author && (
                <TagListItem>
                  <StyledTag>{author}</StyledTag>
                </TagListItem>
              )}

              {date && (
                <TagListItem>
                  <StyledTag>{date}</StyledTag>
                </TagListItem>
              )}
            </TagList>
          )}

          {!!link && (
            <Link link={link} linkTheme="dark">
              {label || t("curiosity_filmdebate_furtherreading")}
            </Link>
          )}
        </Content>
      </ContentWrapper>
    </Container>
  );
};

Edito.propTypes = {
  description: PropTypes.array.isRequired,
  picture: PropTypes.object,
  link: PropTypes.object,
  label: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
};

export default React.memo(Edito);
