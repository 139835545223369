export default ({
  edito_description,
  edito_image,
  edito_link,
  edito_link_label,
  edito_author,
  edito_formatted_publication_date,
}) => {
  return {
    description: edito_description?.raw,
    picture: edito_image.url && edito_image,
    link: edito_link,
    label: edito_link_label,
    author: edito_author,
    date: edito_formatted_publication_date,
  };
};
